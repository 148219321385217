<template>
  <div class="NewElement">
    <div class="Grid-row">
      <div class="Grid-column-12">
        <span>Name</span>
        <input
          v-model="name"
          :class="{ 'has-error': !name && error }"
          class="input"
          type="text"
        />
        <span>Subtitle</span>
        <input
          v-model="subtitle"
          :class="{ 'has-error': !subtitle & error }"
          class="input"
          type="text"
        />
        <span>Description</span>
        <textarea
          v-model="description"
          :class="{ 'has-error': !description && error }"
          class="input input-textarea"
        />
      </div>
      <div class="Grid-column-12">
        <span>Icon URL</span>
        <input
          ref="fileInput"
          class=""
          type="file"
          accept="image/*"
          @change="onFileChange"
        />
        <input
          v-model="icon_url"
          :class="{ 'has-error': !icon_url && error }"
          class="input"
        />
        <div class="Grid-row">
          <span>Element Type: </span>
          <input
            v-model="is_game"
            :value="false"
            class="input-radio"
            type="radio"
          />
          <span>Application</span>
          <input
            v-model="is_game"
            :value="true"
            class="input-radio"
            type="radio"
          />
          <span>HTML5 Game</span>
        </div>
        <tabs v-if="is_game" key="webApplication">
          <tab name="Web Application Data">
            <span>Launch Link</span>
            <input
              v-model="web_data"
              :class="{ 'has-error': !web_data && error }"
              class="input"
              type="text"
            />
          </tab>
        </tabs>
        <tabs v-if="!is_game" key="mobileApplication">
          <tab name="Android Data">
            <span>Package Name</span>
            <input
              v-model="android_data"
              :class="{ 'has-error': !android_data && error }"
              class="input"
              type="text"
            />
          </tab>
          <tab name="IOS Data">
            <span>IOS App Link</span>
            <input
              v-model="ios_link"
              :class="{ 'has-error': !ios_link && error }"
              class="input"
              type="text"
            />
            <span>IOS App ID</span>
            <input
              v-model="ios_app_id"
              :class="{ 'has-error': !ios_app_id && error }"
              class="input"
              type="text"
            />
          </tab>
        </tabs>
      </div>
    </div>
    <div class="Grid-row">
      <button
        v-if="ifIndex"
        class="button button-success"
        @click="updateElement"
      >
        Save Element
      </button>
      <button v-else class="button button-success" @click="addElement">
        Add Element
      </button>
      <button class="button button-danger" @click="cancel">Cancel</button>
    </div>
  </div>
</template>

<script>
import { Tabs, Tab } from 'vue-tabs-component';
import { iconUpload } from '@/utils/aws.js';
import { isNonNegativeInteger } from '@/utils/number.helper';

export default {
  name: 'ElementDetails',
  components: {
    Tabs,
    Tab,
  },
  data() {
    return {
      is_game: false,
      error: false,
      name: null,
      subtitle: null,
      icon_url: null,
      description: null,
      android_data: null,
      ios_link: null,
      ios_app_id: null,
      web_data: null,
    };
  },
  computed: {
    ifIndex() {
      return isNonNegativeInteger(this.$route.params.elementIndex);
    },
    sectionIndex() {
      return this.$route.params.sectionIndex;
    },
    elementIndex() {
      return this.$route.params.elementIndex;
    },
    element() {
      const data = {
        sectionIndex: this.sectionIndex,
        elementIndex: this.elementIndex,
      };
      return isNonNegativeInteger(this.elementIndex)
        ? this.$store.getters.element(data)
        : null;
    },
  },
  watch: {
    element: {
      handler: function (newElement, oldElement) {
        if (!oldElement && newElement) {
          this.populateForm(newElement);
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.element) {
      this.populateForm(this.element);
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'sections' });
    },
    isValidAllData() {
      return this.isValidBasicData() && this.isValidApplicationOrGameData();
    },
    isValidBasicData() {
      return this.name && this.subtitle && this.description && this.icon_url;
    },
    isValidApplicationOrGameData() {
      if (this.is_game) return !!this.web_data;
      else return this.android_data && this.ios_link && this.ios_app_id;
    },
    updateElement() {
      if (!this.isValidAllData()) {
        this.error = true;
      } else {
        const data = {
          sectionIndex: this.$route.params.sectionIndex,
          elementIndex: this.$route.params.elementIndex,
          element: this.payload(),
        };
        this.$store
          .dispatch('updateElement', data)
          .then(this.$router.push({ name: 'sections' }));
      }
    },
    addElement() {
      if (!this.isValidAllData()) {
        this.error = true;
      } else {
        const data = {
          sectionIndex: this.$route.params.sectionIndex,
          element: this.payload(),
        };
        this.$store
          .dispatch('addElement', data)
          .then(this.$router.push({ name: 'sections' }));
      }
    },
    payload() {
      return {
        ...this.basicPayload(),
        ...this.applicationPayload(),
      };
    },
    basicPayload() {
      return {
        name: this.name,
        iconUrl: this.icon_url,
        subtitle: this.subtitle,
        description: this.description,
      };
    },
    applicationPayload() {
      return this.is_game ? this.webPayload() : this.mobilePayload();
    },
    webPayload() {
      return {
        androidData: {
          launchLink: {
            linkUrl: this.web_data,
          },
        },
        iosData: {
          launchLink: {
            linkUrl: this.web_data,
          },
        },
      };
    },
    mobilePayload() {
      return {
        androidData: {
          launchApplication: {
            packageName: this.android_data,
          },
        },
        iosData: {
          launchApplication: {
            iosAppIdentifier: this.ios_app_id,
            iosAppLink: this.ios_link,
          },
        },
      };
    },
    populateForm(element) {
      this.name = element.name;
      this.subtitle = element.subtitle;
      this.icon_url = element.iconUrl;
      this.description = element.description;
      if (
        element.androidData.launchApplication &&
        element.iosData.launchApplication
      ) {
        this.ios_link = element.iosData.launchApplication.iosAppLink;
        this.ios_app_id = element.iosData.launchApplication.iosAppIdentifier;
        this.android_data = element.androidData.launchApplication.packageName;
        this.is_game = false;
      } else if (element.androidData.launchLink && element.iosData.launchLink) {
        this.web_data = element.androidData.launchLink.linkUrl;
        this.is_game = true;
      }
    },
    onFileChange(e) {
      this.$Progress.start();
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return false;
      const file = files[0];

      iconUpload({ file, progress: this.uploadProgress })
        .then((result) => {
          this.icon_url = result.url;
          this.$Progress.finish();
        })
        .catch(() => {
          this.$Progress.fail();
        });
    },
    uploadProgress() {
      // console.log(e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/more-tab/sections/section/NewElement.scss';
</style>
