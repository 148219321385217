import api from '@/api';

export function iconUpload({ file, progress }) {
  return new Promise((resolve, reject) => {
    api
      .getPresignedUrl()
      .then((response) => {
        const publicUrl = response.body.public_url;
        api
          .presignedUrlFileUpload({
            presignedUrl: response.body.presigned_url,
            file,
            progress,
          })
          .then(() => {
            resolve({ url: publicUrl });
          });
      })
      .catch(() => {
        reject(new Error('Icon upload error!'));
      });
  });
}
